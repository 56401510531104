import { ObservationControllerService } from "@9amhealth/openapi";
import {
  getLocalTimeZone,
  now,
  toCalendarDate,
  toZoned
} from "@internationalized/date";
import { Cubit } from "blac-next";
import { Observation } from "fhir/r4";
import { LoincCodingCode } from "src/constants/fhir";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { parseDateMultiTry } from "src/lib/date";

type Glp1RefillBlocState = {
  loading: boolean;
  hasWeightReading: boolean;
};

export default class Glp1RefillBloc extends Cubit<Glp1RefillBlocState> {
  static WEIGHT_READING_TIME_RANGE = 72;
  constructor() {
    super({
      loading: false,
      hasWeightReading: false
    });
  }

  /**
   * Checks if the user has a weight reading form the past WEIGHT_READING_TIME_RANGE hours
   * then updated the state `hasWeightReading`
   */
  checkWeightReading = async () => {
    this.patch({
      loading: true
    });
    const weightValues = await this.fetchWeightReadings();
    this.patch({
      loading: false,
      hasWeightReading: weightValues.length > 0
    });
  };

  /**
   * Fetches the weight readings from the past WEIGHT_READING_TIME_RANGE hours
   * @returns the weight readings
   */
  fetchWeightReadings = async (): Promise<Observation[]> => {
    const currentTime = now(getLocalTimeZone());
    const startTime = currentTime.subtract({
      hours: Glp1RefillBloc.WEIGHT_READING_TIME_RANGE
    });
    try {
      const response = await ObservationControllerService.getObservations(
        toCalendarDate(startTime).toString(),
        toCalendarDate(currentTime).toString(),
        [LoincCodingCode.weight]
      );
      const { data } = response as unknown as {
        status: string;
        data: Observation[];
      };

      const dataInTimeRange = data.filter((entry) => {
        if (!entry.effectiveDateTime) {
          return false;
        }
        const calDateTime = parseDateMultiTry(entry.effectiveDateTime);
        const observationTime = toZoned(calDateTime, getLocalTimeZone());
        return observationTime.compare(startTime) >= 0;
      });

      return dataInTimeRange;
    } catch (e) {
      if (e instanceof Error) {
        addSentryBreadcrumb(
          "api",
          "failed to fetch weight readings:" + e.message
        );
      }
    }
    return [];
  };
}
