import { useBlocNext } from "@blac/react";
import { getLocalTimeZone, now } from "@internationalized/date";
import React, { FC } from "react";
import { GLP1_REFILL_QUESTIONNAIRE_ID } from "src/constants/misc";
import CustomQuestionnaire from "../CustomQuestionnaire/CustomQuestionnaire";
import Loader from "../Loader/Loader";
import WeightForm from "../WeightForm/WeightForm";
import Glp1RefillBloc from "./Glp1RefillBloc";
import translate from "src/lib/translate";
import { taskManagementState } from "src/state/state";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";

const Glp1RefillForm: FC<{ onComplete: () => void }> = (props) => {
  const hours = Glp1RefillBloc.WEIGHT_READING_TIME_RANGE;

  const [{ loading, hasWeightReading }, { checkWeightReading }] = useBlocNext(
    Glp1RefillBloc,
    {
      onMount: (bloc) => {
        void bloc.checkWeightReading();
      }
    }
  );

  const handleQuestionnaireCompleted = () => {
    props.onComplete();
  };

  const refreshTasks = () => {
    void taskManagementState.loadProgramTasks(KnownProgram.CARE);
  };

  return (
    <div>
      {loading && <Loader active fixed />}
      {!loading && (
        <>
          {!hasWeightReading && (
            <WeightForm
              onComplete={() => void checkWeightReading()}
              text={translate("valueRequirements.lastHours", {
                hours
              })}
              minDate={now(getLocalTimeZone()).subtract({
                hours
              })}
            />
          )}
          {hasWeightReading && (
            <CustomQuestionnaire
              id={GLP1_REFILL_QUESTIONNAIRE_ID}
              onLastStepCompleted={handleQuestionnaireCompleted}
              preventLoadingUserAnswers={true}
              onDataSent={refreshTasks}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Glp1RefillForm;
